var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "container",
    },
    [
      _c(
        "b-card",
        {
          staticClass: "border-0",
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-center",
            },
            [_c("h1", [_vm._v(_vm._s(_vm.profile.name))])]
          ),
          _c("full-calendar", {
            ref: "calendar",
            attrs: {
              options: _vm.calendarOptions,
            },
            scopedSlots: _vm._u([
              {
                key: "eventContent",
                fn: function (arg) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex justify-content-center",
                      },
                      [_vm._v(_vm._s(arg.event.title))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-end align-items-center mt-3",
            },
            [
              _c(
                "div",
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mr-1",
                      attrs: {
                        variant: "primary",
                        disabled:
                          _vm.events.added.length + _vm.events.deleted.length <=
                          0,
                      },
                      on: {
                        click: _vm.showSaveConfirm,
                      },
                    },
                    [_vm._v("Submit")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }